import React from 'react'

// Components
import NavBar from './nav-bar'
import Footer from './footer'

// CSS & SCSS
import '~css/font-awesome/css/all.min.css'
import '~css/hs.megamenu.css'
import '~scss/theme.scss'

const Layout = ({ children }) => {

  return (
    <>
      <div className="sticky-top">
        <NavBar />
      </div>
      <div>
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout

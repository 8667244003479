import React from 'react'
import { Link } from 'gatsby'

// Images
import logo from '~images/logo.svg'
import logoColor from '~images/qvr_logo_teal.svg'

const NavBar = () => (
  <header id="header" className="u-header u-header--bg-transparent u-header--abs-top-md u-header--white-nav-links-md u-header--show-hide-md">

    <div className="u-header__section">

      <div id="logoAndNav" className="container">
        {/* Nav */}
        <nav className="navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
          {/* Logo */}
          <Link className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center" to="/" aria-label="QVR">
            <picture>
              <source media="(min-width: 768px)" srcSet={logo} />
              <source srcSet={logoColor} />
              <img src={logo} alt="QVR" style={{ height: `50px` }} />
            </picture>
          </Link>
          {/* End Logo */}

          {/* Responsive Toggle Button */}
          <button type="button" className="navbar-toggler btn u-hamburger"
            aria-label="Toggle navigation"
            aria-expanded="false"
            aria-controls="navBar"
            data-toggle="collapse"
            data-target="#navBar">
            <span id="hamburgerTrigger" className="u-hamburger__box">
              <span className="u-hamburger__inner"></span>
            </span>
          </button>
          {/* End Responsive Toggle Button */}

          {/* Navigation */}
          <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse nav-fill nav-pills">
            <ul className="navbar-nav u-header__navbar-nav">

              {/* About QVR */}
              <li className="nav-item u-header__nav-item u-header__nav-link">
                <Link className="nav-link text-white" activeClassName="active" to="/about">About QVR</Link>
              </li>
              {/* End About QVR */}

              {/* Pricing */}
              <li className="nav-item u-header__nav-item u-header__nav-link">
                <Link className="nav-link text-white" to="/pricing" activeClassName="active" >Pricing</Link>
              </li>
              {/* End Pricing */}

              {/* old-Pricing */}
              <li className="nav-item u-header__nav-item u-header__nav-link">
                <Link className="nav-link text-white" to="/old-pricing" activeClassName="active" >Demo</Link>
              </li>
              {/* End old-Pricing */}

              {/* Contact Us */}
              <li className="nav-item u-header__nav-item u-header__nav-link">
                <Link className="nav-link text-white" to="/contact" activeClassName="active" >Contact Us</Link>
              </li>
              {/* End Contact Us */}

              {/* Demo */}
              {/* <li className="nav-item u-header__nav-last-item"> */}
                {/* <Link className="nav-link text-white" activeClassName="active"  to="/demo"> */}
                {/* <Link className="btn btn-sm btn-white transition-3d-hover" activeClassName="btn-primary"  to="/demo"> */}
                  {/* Demo/ */}
                {/* </Link>
              </li> */}
              {/* End Demo */}

            </ul>
          </div>
          {/* End Navigation */}
        </nav>
        {/* End Nav */}
      </div>
    </div>
  </header>
)

export default NavBar

import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="container text-center space-2">
      <ul className="list-inline mb-4">
        {/* <li className="list-inline-item">
          <a className="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="https://facebook.com">
            <span className="fab fa-facebook-f btn-icon__inner"></span>
          </a>
        </li>
        <li className="list-inline-item">
          <a className="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="https://twitter.com">
            <span className="fab fa-twitter btn-icon__inner"></span>
          </a>
        </li>
        <li className="list-inline-item">
          <a className="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="https://instagram.com">
            <span className="fab fa-instagram btn-icon__inner"></span>
          </a>
        </li> */}
        <li className="list-inline-item">
          <a className="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" target="blank" href="https://www.linkedin.com/company/qvr/">
            <span className="fab fa-linkedin-in btn-icon__inner"></span>
          </a>
        </li>
      </ul>

      <p className="small text-muted mb-0">&copy; {`${year}. QVR All rights reserved`}</p>
    </footer>
  )
}

export default Footer
